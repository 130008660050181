<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card w-100 border-light">
      <div
        class="card-header d-flex flex-row align-items-start justify-content-between"
      >
        <div
          style="width: 70%"
          class="d-flex justify-content-start align-items-center"
        >
          <div
            class="d-flex flex-column align-itesm-start justify-content-start"
          >
            <h2 class="mb-0 font-weight-bolder">
              <span class="align-middle">Create new question</span>
            </h2>
            <p class="font-weight-bold text-muted mb-0">
              Complete the form and click submit to create new question.
            </p>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <b-button
            size="sm"
            @click="closeSidebar()"
            variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>
      <div class="card-body">
        <validation-observer ref="assess_form" #default="{ invalid }">
          <b-form class="mt-2" @submit.prevent="handleSubmitClick">
            <b-form-group label="Name">
              <template slot="label">
                Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                vid="title"
                #default="{ errors }"
                name="Name"
                :rules="{
                  required: true,
                }"
              >
                <b-form-input v-model="formData.title"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Description">
              <validation-provider
                vid="description"
                #default="{ errors }"
                name="Description"
              >
                <b-form-textarea
                  id="description"
                  v-model="formData.description"
                  rows="4"
                  max-rows="6"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Answer">
              <template slot="label">
                Answer <span class="text-danger">*</span>
              </template>
              <validation-provider
                vid="answer"
                #default="{ errors }"
                name="Answer"
                :rules="{
                  required: true,
                }"
              >
                <b-form-select
                  v-model="formData.answer"
                  :options="answerOptions"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="d-flex align-items-center justify-content-start">
              <b-form-group class="mr-2">
                <validation-provider
                  vid="is_evidence_rqd"
                  #default="{ errors }"
                  name="is_evidence_rqd"
                  :rules="{
                    required: false,
                  }"
                >
                  <b-form-checkbox
                    v-model="formData.evidenceRequired"
                    :value="true"
                    class="custom-control-primary"
                  >
                    Evidence Required
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="mr-2">
                <validation-provider
                  vid="is_response_rqd"
                  #default="{ errors }"
                  name="is_response_rqd"
                  :rules="{
                    required: false,
                  }"
                >
                  <b-form-checkbox
                    v-model="formData.responseMandatory"
                    :value="true"
                    class="custom-control-primary"
                  >
                    Response Mandatory
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <!-- Parent Section -->
            <div class="card bg-light-secondary">
              <div
                class="card-header d-flex flex-column align-items-start justify-content-start"
              >
                <h4>Add Parent Question</h4>
                <p class="text-muted">Fill if a parent question exist</p>
              </div>
              <div class="card-body">
                <b-form-group label="Parent Question">
                  <template slot="label">
                    Parent Question <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="User"
                    :rules="{
                      required: false,
                    }"
                  >
                    <vue-autosuggest
                      id="autosuggest__input"
                      :input-props="{
                        class: 'form-control',
                        placeholder: 'Search Questions...',
                      }"
                      :suggestions="parentQuestionSuggestions"
                      :limit="5"
                      v-model="formData.parentQuestionName"
                      @selected="onQuestionSelected"
                      @input="questionGetSuggestionValue"
                    >
                      <template
                        slot-scope="{ suggestion }"
                        style="display: flex; align-items: center"
                      >
                        <span style="{ display: 'flex', color: 'navyblue'}">{{
                          suggestion.item.title
                        }}</span>
                      </template>
                    </vue-autosuggest>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Parent Response">
                  <template slot="label">
                    Parent Response <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    vid="parent_question_response"
                    #default="{ errors }"
                    name="Parent Response"
                    :rules="{
                      required:
                        formData.parentQuestionId !== null ? true : false,
                    }"
                  >
                    <b-form-select
                      v-model="formData.parentQuestionResponse"
                      :options="answerOptions"
                    ></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>

            <b-button
              type="submit"
              variant="primary"
              class="mt-1"
              :disabled="invalid"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Submit</span>
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormSelect,
  BFormTextarea,
  BBadge,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min } from "@validations";
import ResponseMixins from "../../../../mixins/ResponseMixins";
import QuestionnaireMixins from "../../../../mixins/QuestionnaireMixins";
import UtilsMixins from "../../../../mixins/UtilsMixins";

export default {
  components: {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    required,
    min,
    BBadge,
    BSpinner,
    VBTooltip,
    VueAutosuggest,
  },
  data() {
    return {
      answerOptions: [
        { value: null, text: "--Select a value--" },
        { value: 1, text: "Yes" },
        { value: 0, text: "No" },
      ],
      showOverlay: false,
      parentQuestionSuggestions: [],
      formData: {
        title: null,
        description: null,
        evidenceRequired: false,
        parentQuestionId: null,
        parentQuestionName: null,
        parentQuestionResponse: null,
        responseMandatory: false,
        answer: null,
      },
    };
  },
  props: {
    reloadParent: {
      type: Function,
      required: true,
    },

    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  mixins: [ResponseMixins, QuestionnaireMixins, UtilsMixins],
  mounted() {},
  methods: {
    questionGetSuggestionValue(searchPhrase) {
      this.showOverlay = true;
      this.debounceFunction(() => {
        this.getQuestionsFromQuestionnaire(
          { limit: 5, search: searchPhrase },
          this.$route.params.id
        )
          .then((res) => {
            this.parentQuestionSuggestions = [];
            let questions = res.data.data.data;
            this.parentQuestionSuggestions.push({
              name: "questions",
              data: questions,
            });
          })
          .catch((err) => {
            this.handleError(err);
            console.log(err);
          })
          .finally(() => {
            this.showOverlay = false;
          });
      });
    },
    onQuestionSelected(question) {
      this.formData.parentQuestionId = question.item._id;
      this.formData.parentQuestionName = question.item.title;
    },
    handleSubmitClick() {
      this.showOverlay = true;
      this.addQuestionToQuestionnaire(this.formData, this.$route.params.id)
        .then((res) => {
          this.handleResponse(res);
          this.reloadParent();
          this.closeSidebar();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style>
</style>
