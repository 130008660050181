<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card">
      <b-sidebar
        lazy
        id="sidebar-add-questionnire"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        backdrop
        right
        no-header
        shadow
      >
        <add-question-form
          :closeSidebar="closeAddQuestionSidebar"
          :reloadParent="load"
        />
      </b-sidebar>
      <b-sidebar
        lazy
        id="sidebar-edit-questionnire"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        backdrop
        right
        no-header
        shadow
      >
        <edit-question-form
          v-if="selectedQuestionId && selectedQuestionId !== null"
          :closeSidebar="closeEditQuestionSidebar"
          :reloadParent="load"
          :questionId="selectedQuestionId"
        />
      </b-sidebar>
      <div
        class="
          card-header
          d-flex
          flex-row
          align-items-start
          justify-content-between
        "
        v-if="questionnaire !== null"
      >
        <div
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-start
            w-75
          "
        >
          <h2 class="text-primary">{{ questionnaire.title }}</h2>
          <p>{{ questionnaire.description }}</p>
        </div>
      </div>

      <div class="card-body" v-if="questions !== null">
        <div class="d-flex align-items-start justify-content-start my-1">
          <b-button variant="success" @click="handleAddQuestionClick"
            ><feather-icon icon="PlusIcon" class="mr-50" />New
            Question</b-button
          >
        </div>
        <table role="table" class="table">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">Name</th>
              <th role="columnheader" scope="col">Description</th>
              <th role="columnheader" scope="col" class="text-center">
                Evidence Required
              </th>
              <th role="columnheader" scope="col" class="text-center">
                Response Mandatory
              </th>
              <th role="columnheader" scope="col" class="text-center">
                Answer
              </th>
              <th role="columnheader" scope="col" class="text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr v-for="question in questions" :key="question._id" role="row">
              <td role="cell" style="width: 300px">
                <div
                  class="d-flex justify-content-start align-items-center"
                  style="width: 300px"
                  v-b-tooltip.hover.top.v-secondary
                  :title="question.title"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <feather-icon
                      icon="HelpCircleIcon"
                      class="mr-50 text-primary"
                    />
                  </div>
                  <p class="text-primary font-weight-bolder mb-0 text-truncate">
                    {{ question.title }}
                  </p>
                </div>
              </td>

              <td role="cell" style="width: 300px">
                <div
                  class="d-flex justify-content-start"
                  style="width: 300px"
                  v-b-tooltip.hover.top.v-secondary
                  :title="question.description"
                >
                  <p class="font-weight-bold mb-0">
                    {{ question.description }}
                  </p>
                </div>
              </td>

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :text="getMandatoryValue(question.is_evidence_rqd)"
                    :variant="
                      question.is_evidence_rqd == 1
                        ? 'light-success'
                        : 'light-danger'
                    "
                  />
                </div>
              </td>

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :text="getMandatoryValue(question.is_response_mandatory)"
                    :variant="
                      question.is_response_mandatory == 1
                        ? 'light-success'
                        : 'light-danger'
                    "
                  />
                </div>
              </td>

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :text="getMandatoryValue(question.answer)"
                    :variant="
                      question.answer == 1 ? 'light-success' : 'light-danger'
                    "
                  />
                </div>
              </td>

              <td aria-colindex="2" role="cell">
                <div class="d-flex align-items-center justify-content-center">
                  <b-button
                    variant="primary"
                    class="btn-icon rounded-circle mx-50"
                    v-b-tooltip.hover.top.v-secondary
                    title="Edit Question"
                    @click="editQuestionClick(question._id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    variant="danger"
                    class="btn-icon rounded-circle mx-50"
                    v-b-tooltip.hover.top.v-secondary
                    title="Remove Question"
                    @click="removeQuestionClick(question._id)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ResponseMixins from "../../../mixins/ResponseMixins";
import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import {
  BBadge,
  BOverlay,
  BAvatar,
  BButton,
  VBTooltip,
  BSidebar,
} from "bootstrap-vue";
import TaskMixins from "../../../mixins/TaskMixins";
import AddQuestionForm from "./components/AddQuestionForm.vue";
import EditQuestionForm from "./components/EditQuestionForm.vue";

export default {
  components: {
    BBadge,
    BOverlay,
    BAvatar,
    BButton,
    AddQuestionForm,
    BSidebar,
    EditQuestionForm,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      selectedQuestionId: null,
      showOverlay: false,
      questionnaire: null,
      questions: null,
    };
  },
  mixins: [ResponseMixins, QuestionnaireMixins, TaskMixins],
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getQuestionnaireDetailsAndSetData(this.$route.params.id);
    },
    editQuestionClick(questionId) {
      this.selectedQuestionId = questionId;
      this.openEditQuestionSidebar();
    },
    closeEditQuestionSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit-questionnire");
    },
    openEditQuestionSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-edit-questionnire");
      });
    },
    removeQuestionClick(questionId) {
      this.showOverlay = true;
      this.removeQuestionFromQuestionnaire(this.$route.params.id, questionId)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    handleAddQuestionClick() {
      this.openAddQuestionSidebar();
    },
    closeAddQuestionSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-add-questionnire");
    },
    openAddQuestionSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-add-questionnire");
      });
    },
    getQuestionnaireDetailsAndSetData(questionnaireId) {
      this.showOverlay = true;
      this.getImportedQuestionnaireDetails(questionnaireId)
        .then((res) => {
          this.questionnaire = res.data.data;
          this.questions = res.data.data.questions;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return null;
        }
      }
    },
    getMandatoryValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 0:
            return "No";
          default:
            return "No";
        }
      }
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 70rem !important;
}
</style>